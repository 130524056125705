import React, { useEffect, useState } from "react";
import { graphql } from "gatsby"
import * as Waline from '@waline/client';
import '@waline/client/dist/waline.css';

import Layout from "../components/layout"

export default function Post({data}) {
  const post = data.notion;
  let [inited, setInited] = useState(false);

  useEffect(() => {
    if (!inited) {
      const waline = Waline.init({
        el: `#comment`,
        serverURL: 'https://erl-comment.netlify.app/.netlify/functions/comment',
      });
      
      waline.update({
        login: 'disable'
      });
      setInited(true);
    }
  })

  return (
    <Layout siteInfo={data.site.siteMetadata}>
        <article class="post">
          <header class="post__header">
            <h1 class="post__title">{ post.title }</h1>
            <div className="post__datetime">
              { post.raw.icon && post.raw.icon.emoji }
              <time style={{
                marginLeft: 10
              }}>{ post.createdAt }</time>
            </div>
          </header>
          <div className="post__content content" dangerouslySetInnerHTML={{ __html: post.childMarkdownRemark.html }} />
          <div id="comment" className="post__comment"></div>
        </article>
    </Layout>
  )
}

export const Head = ({data}) => {
  const site = data.site.siteMetadata;
  const title = data.notion.title;
  return (
    <>
      <title>{ title } - { site.title }</title>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        description
        title
      }
    }
    notion(id: {eq: $id}) {
      childMarkdownRemark {
        html
      }
      raw {
        icon {
          emoji
        }
      }
      title
      createdAt(formatString: "YYYY-MM-DD HH:MM")
    }
  }
`
